/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Modal, Input, Select, Form, Button, message } from "antd";
import axios from "axios";

const { TextArea } = Input;

const SecureNoteUpdate = ({
  isModalVisible,
  setIsModalVisible,
  editNote,
  onSuccess,
}) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (editNote) {
      form.setFieldsValue({
        title: editNote.name,
        note: editNote.note,
        app: editNote.collections,
      });
    } else {
      form.resetFields();
    }
  }, [editNote, form]);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken && isModalVisible) {
      fetchApps(storedToken);
    }
  }, [isModalVisible]);

  const fetchApps = async (token) => {
    try {
      const response = await axios.get(`${API_URL}/api/apps/get-apps`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const apps = response.data.apps || [];

      const formattedOptions = [
        { label: "None", value: null },
        ...apps.map((app) => ({
          label: app.name,
          value: app.id,
        })),
      ];

      setOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching apps:", error);
      message.error("Failed to fetch apps");
    }
  };

  const handleUpdate = async (values) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");

      await axios.put(
        `${API_URL}/api/secure-note/update-secure-note`,
        {
          title: values.title,
          note: values.note,
          app_id: values.app,
          noteId: editNote.key,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      message.success("Note updated successfully");
      setIsModalVisible(false);

      const updatedNote = {
        key: editNote.noteId,
        name: values.title,
        note: values.note,
        collections: values.app,
        lastUpdate: new Date().toLocaleString(),
      };

      if (onSuccess) {
        onSuccess(updatedNote);
      }
    } catch (error) {
      console.error("Error during update:", error);

      if (error.response) {
        console.error("API error response:", error.response.data);
      }

      message.error("Failed to update the note.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Update Secure Note"
      open={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      footer={[
        <Button key="back" onClick={() => setIsModalVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => form.submit()}
        >
          Update
        </Button>,
      ]}
    >
      <Form form={form} onFinish={handleUpdate} layout="vertical">
        <Form.Item
          label="Note Title"
          name="title"
          rules={[{ required: true, message: "Please enter title!" }]}
        >
          <Input placeholder="Enter note title" />
        </Form.Item>
        <Form.Item
          label="Note Content"
          name="note"
          rules={[{ required: true, message: "Please enter note content!" }]}
        >
          <TextArea rows={4} placeholder="Enter note content" />
        </Form.Item>
        <Form.Item label="Select Apps" name="app">
          <Select
            allowClear
            placeholder="Select apps"
            options={options}
            value={form.getFieldValue("app")}
            onChange={(value) => form.setFieldsValue({ app: value })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SecureNoteUpdate;
