/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Card, Col, Row, Table, Typography } from "antd";
import axios from "axios";
import { AppstoreOutlined, KeyOutlined, UserOutlined } from "@ant-design/icons";

const { Title } = Typography;

const Dashboard = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [summaryCards, setSummaryCards] = useState([
    {
      title: "Apps",
      count: 0,
      icon: <AppstoreOutlined style={{ fontSize: "32px" }} />,
    },
    {
      title: "Users",
      count: 0,
      icon: <UserOutlined style={{ fontSize: "32px" }} />,
    },
    {
      title: "Secrets",
      count: 0,
      icon: <KeyOutlined style={{ fontSize: "32px" }} />,
    },
  ]);

  // const config = {
  //   width: 150,
  //   height: 150,
  //   autoFit: true,
  //   data: {
  //     target: summaryCards.count,
  //     total: 100,
  //     name: summaryCards.title,
  //     thresholds: [20, 40, 60, 80, 100],
  //   },
  //   legend: false,
  //   scale: {
  //     color: {
  //       range: ["green", "#FAAD14", "#F4884A", "#F4664A", "#d42906"],
  //     },
  //   },
  //   style: {
  //     textContent: (name, target) => `\n\n ${name}:${target}`,
  //   },
  // };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const userId = decodedToken.id || decodedToken.userId;

    const fetchUsers = async () => {
      try {
        const response = await axios.post(
          `${API_URL}/api/invite/get-invited-users`,
          {
            userId,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setUsers(response.data);
        updateSummaryCard("Users", response.data.length);
        setLoading(false);
      } catch (error) {
        console.error("Kullanıcılar çekilirken hata oluştu:", error);
        setLoading(false);
      }
    };

    const fetchSecretCountByUserId = async () => {
      try {
        const response = await axios.post(
          `${API_URL}/api/secrets/get-user-secret-count`,
          {
            userId,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const secretCount = response.data[0].secret_count;
        updateSummaryCard("Secrets", secretCount);
      } catch (error) {
        console.error("Error fetching secret count:", error);
      }
    };

    const fetchApps = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/apps/get-apps`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const appsCount = response.data.apps.length;
        updateSummaryCard("Apps", appsCount);
      } catch (error) {
        console.error("Error fetching apps:", error);
      }
    };

    const updateSummaryCard = (title, count) => {
      setSummaryCards((prevCards) =>
        prevCards.map((card) =>
          card.title === title
            ? {
                ...card,
                count: typeof count === "function" ? count(card.count) : count,
              }
            : card
        )
      );
    };

    fetchUsers();
    fetchSecretCountByUserId();
    fetchApps();
  }, []);

  const columns = [
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
    },
    // {
    //   title: "Son Giriş",
    //   dataIndex: "updated_at",
    //   key: "lastLogin",
    //   render: (text) => new Date(text).toLocaleString(),
    // },
    {
      title: "Access Level",
      dataIndex: "role",
      key: "role",
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <style jsx>{`
        @media (max-width: 768px) {
          .ant-card {
            margin-bottom: 10px;
          }
        }
        @media (max-width: 576px) {
          .ant-row {
            flex-direction: column;
          }
        }
      `}</style>
      <Row gutter={[16, 16]}>
        {summaryCards.map((card) => (
          <Col xs={24} sm={12} md={8} key={card.title}>
            <Card>
              <Row justify="space-around" align="middle">
                <Col>
                  {/* <Gauge {...config} /> */}

                  <Title level={4}>{card.title}</Title>
                  <Title
                    level={2}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    {card.count}
                  </Title>
                </Col>
                <Col>{card.icon}</Col>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
      <div style={{ marginTop: "20px" }}>
        <Card title="Users" bordered={false}>
          <Table
            columns={columns}
            dataSource={users}
            loading={loading}
            pagination={{ pageSize: 5 }}
            scroll={{ x: 600 }}
          />
        </Card>
      </div>
    </div>
  );
};

export default Dashboard;
