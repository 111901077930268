// Karanlık tema renkleri
export const darkTokens = {
  colorPrimary: "#9BA4B4",
  colorTextBase: "#F1F6F9",
  colorBgBase: "#394867",
  colorBgContainer: "#394867",
  colorLink: "#394867",
  colorLinkHover: "#14274E",
  colorLinkActive: "#9BA4B4",
  colorLinkVisited: "#394867",
};

export const lightTokens = {
  colorPrimary: "#14274E",
  colorTextBase: "#394867",
  colorLink: "#394867",
  colorLinkHover: "#14274E",
  colorLinkActive: "#9BA4B4",
  colorLinkVisited: "#394867",
};

export const componentOverridesLight = {
  Button: {
    colorPrimary: "#14274E",
    colorText: "#394867",
    borderRadiusBase: "5px",
  },
  Switch: {
    colorPrimary: "#394867",
    colorText: "#394867",
    borderRadiusBase: "5px",
  },
  Checkbox: {
    colorPrimary: "#9BA4B4",
    colorText: "#394867",
    borderRadiusBase: "5px",
    colorTextDisabled: "#cccc",
    colorBgContainerDisabled: "#cccc",
  },
  Radio: {
    colorPrimary: "#9BA4B4",
    colorText: "#394867",
    borderRadiusBase: "5px",
  },
};

export const componentOverridesDark = {
  Button: {
    colorPrimary: "#9BA4B4",
    colorText: "#F1F6F9",
    borderRadiusBase: "5px",
  },
  Switch: {
    colorPrimary: "#9BA4B4",
    colorText: "#F1F6F9",
    borderRadiusBase: "5px",
  },
  Checkbox: {
    colorPrimary: "#F1F6F9",
    colorText: "#F1F6F9",
    borderRadiusBase: "5px",
  },
  Radio: {
    colorPrimary: "#394867",
    colorText: "#F1F6F9",
    borderRadiusBase: "5px",
  },
};
