/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Space,
  Table,
  Tag,
  Typography,
  message,
  Modal,
  Row,
  Col,
  Input,
  Tabs,
  Form,
} from "antd";
import {
  EyeOutlined,
  CopyOutlined,
  EyeInvisibleOutlined,
  FolderOutlined,
  KeyOutlined,
  ArrowLeftOutlined,
  SearchOutlined,
  ReadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import axios from "axios";
import "./SharedItems.css";
import TextArea from "antd/es/input/TextArea";

const { Title } = Typography;

const SharedItems = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [data, setData] = useState([]);
  const [mySharedData, setMySharedData] = useState([]);
  const [showSecretValues, setShowSecretValues] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedFolderContents, setSelectedFolderContents] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filteredMySharedData, setFilteredMySharedData] = useState([]);
  const [userId, setUserId] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [backStack, setBackStack] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [isNoteModalVisible, setIsNoteModalVisible] = useState(false);

  const fetchSharedItems = async (userId, token) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/share/get-shared-items`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const items = response.data.sharedItems || [];
      setData(items);
      setFilteredData(items);
    } catch (error) {
      console.error("Error fetching shared items:", error);
      message.error("An error occurred while fetching shared items.");
    }
  };

  const fetchMySharedItems = async (token) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/share/get-my-shared-items`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const items = response.data.sharedItems || [];
      setMySharedData(items);
      setFilteredMySharedData(items);
    } catch (error) {
      console.error("Error fetching my shared items:", error);
      message.error("An error occurred while fetching my shared items.");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      message.error("Token not found.");
      return;
    }
    try {
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      setUserId(decodedToken.id);
      fetchSharedItems(decodedToken.id, token);
      fetchMySharedItems(token);
    } catch (error) {
      console.error("Error decoding token:", error);
      message.error("Invalid token.");
    }
  }, []);

  const toggleSecretVisibility = (id) => {
    setShowSecretValues((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const openFolderModal = (contents, folderName) => {
    if (selectedFolderContents.length > 0) {
      setBackStack((prevStack) => [
        ...prevStack,
        { contents: selectedFolderContents, title: modalTitle },
      ]);
    }
    setSelectedFolderContents(contents);
    setFilteredData(contents);
    setModalTitle(folderName);
    setIsModalVisible(true);
  };

  const goBack = () => {
    const lastState = backStack.pop();
    if (lastState) {
      setSelectedFolderContents(lastState.contents);
      setFilteredData(lastState.contents);
      setModalTitle(lastState.title);
    }
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setBackStack([]);
    setSelectedFolderContents([]);
    setModalTitle("");
    setFilteredData(data);
  };

  const openNoteModal = (note) => {
    if (note.type === "Secure Note") {
      setSelectedNote(note);
      setIsNoteModalVisible(true);
    } else {
      message.error("Only Secure Notes can be viewed.");
    }
  };

  const closeNoteModal = () => {
    setSelectedNote(null);
    setIsNoteModalVisible(false);
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchValue(value);
    setFilteredData(
      data.filter((item) => item.name.toLowerCase().includes(value))
    );
  };

  const handleSearchMyShared = (e) => {
    const value = e.target.value.toLowerCase();
    setFilteredMySharedData(
      mySharedData.filter((item) => item.name.toLowerCase().includes(value))
    );
  };

  const handleDeleteMySharedItem = async (record) => {
    // Confirm penceresi isteğe bağlı
    const confirm = window.confirm(
      `Are you sure you want to delete "${record.name}"?`
    );
    if (!confirm) return;

    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // API body: { sharedItemIds: [record.id] }
      await axios.post(
        `${API_URL}/api/share/delete-my-shared-items`,
        { sharedItemIds: [record.shared_item_id] },
        config
      );

      message.success(`"${record.name}" deleted successfully!`);

      // Lokal state’ten de silelim
      setMySharedData((prev) => prev.filter((item) => item.id !== record.id));
      setFilteredMySharedData((prev) =>
        prev.filter((item) => item.id !== record.id)
      );
    } catch (error) {
      console.error("Error deleting shared item:", error);
      message.error("An error occurred while deleting the item.");
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Space>
          {record.type === "Folder" ? (
            <span
              onClick={() => openFolderModal(record.contents, record.name)}
              style={{ cursor: "pointer" }}
            >
              <FolderOutlined /> <strong>{text}</strong>
            </span>
          ) : record.type === "Secret" ? (
            <span>
              <KeyOutlined /> <strong>{text}</strong>
            </span>
          ) : record.type === "Secure Note" ? (
            <span
              onClick={() => openNoteModal(record)}
              style={{ cursor: "pointer" }}
            >
              <ReadOutlined /> <strong>{text}</strong>
            </span>
          ) : null}
        </Space>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type) => (
        <Tag
          color={
            type === "Folder" ? "blue" : type === "Secret" ? "green" : "purple"
          }
        >
          {type}
        </Tag>
      ),
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (text, record) =>
        record.type === "Secret" ? (
          <div className="flex justify-between items-center">
            <span>{showSecretValues[record.id] ? text : "••••••••"}</span>
            <Space>
              <Button
                icon={
                  showSecretValues[record.id] ? (
                    <EyeInvisibleOutlined />
                  ) : (
                    <EyeOutlined />
                  )
                }
                type="text"
                onClick={() => toggleSecretVisibility(record.id)}
              />
              <Button
                icon={<CopyOutlined />}
                type="text"
                onClick={() => {
                  navigator.clipboard.writeText(text);
                  message.success("Secret copied!");
                }}
              />
            </Space>
          </div>
        ) : (
          "-"
        ),
    },
  ];

  const MySharedColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Space>
          {record.type === "Folder" ? (
            <span
              onClick={() => openFolderModal(record.contents, record.name)}
              style={{ cursor: "pointer" }}
            >
              <FolderOutlined /> <strong>{text}</strong>
            </span>
          ) : record.type === "Secret" ? (
            <span>
              <KeyOutlined /> <strong>{text}</strong>
            </span>
          ) : record.type === "Secure Note" ? (
            <span
              onClick={() => openNoteModal(record)}
              style={{ cursor: "pointer" }}
            >
              <ReadOutlined /> <strong>{text}</strong>
            </span>
          ) : null}
        </Space>
      ),
    },
    {
      title: "Shared To",
      dataIndex: "shared_to",
      key: "shared_to",
      render: (text) => <span>{text || "Unknown"}</span>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type) => (
        <Tag
          color={
            type === "Folder" ? "blue" : type === "Secret" ? "green" : "purple"
          }
        >
          {type}
        </Tag>
      ),
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (text, record) =>
        record.type === "Secret" ? (
          <div className="flex justify-between items-center">
            <span>{showSecretValues[record.id] ? text : "••••••••"}</span>
            <Space>
              <Button
                icon={
                  showSecretValues[record.id] ? (
                    <EyeInvisibleOutlined />
                  ) : (
                    <EyeOutlined />
                  )
                }
                type="text"
                onClick={() => toggleSecretVisibility(record.id)}
              />
              <Button
                icon={<CopyOutlined />}
                type="text"
                onClick={() => {
                  navigator.clipboard.writeText(text);
                  message.success("Secret copied!");
                }}
              />
            </Space>
          </div>
        ) : (
          "-"
        ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Button
          type="danger"
          onClick={() => handleDeleteMySharedItem(record)}
          style={{ width: "100%", maxWidth: 200, color: "red" }}
        >
          <DeleteOutlined />
        </Button>
      ),
    },
  ];

  const items = [
    {
      key: "1",
      label: "Shared Items",
      children: (
        <div style={{ padding: "12px 0" }}>
          <Row gutter={[16, 16]} justify="space-between" align="middle">
            <Col xs={24} md={12}>
              <Title level={3}>Shared Items</Title>
            </Col>
            <Col xs={24} md={12} className="search">
              <Input
                placeholder="Search..."
                prefix={<SearchOutlined />}
                value={searchValue}
                onChange={handleSearch}
                style={{ width: "100%", maxWidth: 200 }}
              />
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={filteredData}
            pagination={{ pageSize: 8 }}
            rowKey="id"
            scroll={{ x: "max-content" }}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "My Shared Items",
      children: (
        <div style={{ padding: "12px 0" }}>
          <Row gutter={[16, 16]} justify="space-between" align="middle">
            <Col xs={24} md={12}>
              <Title level={3}>My Shared Items</Title>
            </Col>
            <Col xs={24} md={12} className="search">
              <Input
                placeholder="Search..."
                prefix={<SearchOutlined />}
                onChange={handleSearchMyShared}
                style={{ width: "100%", maxWidth: 200 }}
              />
            </Col>
          </Row>
          <Table
            columns={MySharedColumns}
            dataSource={filteredMySharedData}
            pagination={{ pageSize: 8 }}
            rowKey={(record, index) => `${record.name}-${index}`}
            scroll={{ x: "max-content" }}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <Tabs defaultActiveKey="1" items={items} />
      <Modal
        title={
          <Row align="middle" gutter={[8, 8]}>
            {backStack.length > 0 && (
              <Col>
                <Button
                  type="text"
                  icon={<ArrowLeftOutlined />}
                  onClick={goBack}
                >
                  Back
                </Button>
              </Col>
            )}
            <Col flex="auto">
              <Title level={3} style={{ margin: 0 }}>
                {modalTitle}
              </Title>
            </Col>
          </Row>
        }
        open={isModalVisible}
        onCancel={closeModal}
        footer={null}
        width="80%"
        styles={{ body: { overflowX: "auto" } }}
      >
        <Table
          columns={columns}
          dataSource={selectedFolderContents}
          pagination={false}
          rowKey={(record) => record.id}
          scroll={{ x: "max-content" }}
        />
      </Modal>
      <Modal
        title={
          <Row align="middle" gutter={[8, 8]}>
            {backStack.length > 0 && (
              <Col>
                <Button
                  type="text"
                  icon={<ArrowLeftOutlined />}
                  onClick={goBack}
                >
                  Back
                </Button>
              </Col>
            )}
            <Col flex="auto">
              <Title level={3} style={{ margin: 0 }}>
                {modalTitle}
              </Title>
            </Col>
          </Row>
        }
        open={isModalVisible}
        onCancel={closeModal}
        footer={null}
        width="80%"
        styles={{ body: { overflowX: "auto" } }}
      >
        <Table
          columns={columns}
          dataSource={selectedFolderContents}
          pagination={false}
          rowKey={(record) => record.id}
          scroll={{ x: "max-content" }}
        />
      </Modal>
      <Modal
        open={isNoteModalVisible}
        onCancel={closeNoteModal}
        footer={[
          <Button key="back" onClick={closeNoteModal}>
            Close
          </Button>,
        ]}
      >
        {selectedNote ? (
          <Form layout="vertical">
            <Form.Item label="Note Title">
              <Input value={selectedNote.name} readOnly />
            </Form.Item>
            <Form.Item label="Note Content">
              <TextArea rows={8} value={selectedNote.value} readOnly />
            </Form.Item>
          </Form>
        ) : (
          <p>No note selected.</p>
        )}
      </Modal>
    </>
  );
};

export default SharedItems;
