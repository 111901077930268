import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./AuthForm.css";
import { Button, Divider, Form, Input, Typography, message } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import axios from "axios";

const { Title } = Typography;

function AuthForm({ onLoginSuccess }) {
  const API_URL = process.env.REACT_APP_API_URL;
  const [isSignup, setIsSignup] = useState(false);
  const [loginEmail, setLoginEmail] = useState("");
  const [signupEmail, setSignupEmail] = useState("");
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    const savedLoginEmail = localStorage.getItem("userEmail");
    if (savedLoginEmail) {
      setLoginEmail(savedLoginEmail);
      form.setFieldsValue({ email: savedLoginEmail });
    }
  }, [form]);

  const onFinish = async (values) => {
    try {
      const email = isSignup ? signupEmail : loginEmail;

      if (isSignup) {
        if (values.password !== values.confirmPassword) {
          message.error("Passwords do not match!");
          return;
        }

        const response = await axios.post(
          `${API_URL}/api/auth/register`,
          {
            name: values.username,
            email,
            password: values.password,
            role: "user",
          },
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        message.success("Registration successful!");
        localStorage.setItem("token", response.data.token);
        navigate("/verify");
      } else {
        const response = await axios.post(
          `${API_URL}/api/auth/login`,
          {
            email,
            password: values.password,
          },
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          message.success("Login successful!");
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("userEmail", email);
          onLoginSuccess();
          navigate("/dashboard");
        } else {
          message.error("Login failed. Please try again.");
        }
      }
    } catch (error) {
      const errorMsg =
        error.response?.data?.error || "An error occurred. Please try again.";
      message.error(errorMsg);
    }
  };

  const handleToggle = () => {
    setIsSignup((prev) => !prev);
    form.resetFields();
  };

  return (
    <div className="container">
      <Form
        form={form}
        name="authForm"
        layout="vertical"
        className="form"
        style={isSignup ? { padding: "20px 20px" } : {}}
        onFinish={onFinish}
      >
        <div className="logo">
          <img src="/logo3.png" alt="Logo" width={120} />
        </div>

        <Title level={3} className="title">
          {isSignup ? "Signup" : "Login"}
        </Title>

        {isSignup && (
          <Form.Item
            label="Username"
            name="username"
            rules={[{ message: "Please input your username!" }]}
          >
            <Input placeholder="Enter your username" />
          </Form.Item>
        )}

        <Form.Item
          label="Email Address"
          name="email"
          rules={[
            {
              message: "Please input your email!",
              type: "email",
            },
          ]}
        >
          <Input
            prefix={<MailOutlined />}
            placeholder="Enter your email"
            value={isSignup ? signupEmail : loginEmail}
            onChange={(e) =>
              isSignup
                ? setSignupEmail(e.target.value)
                : setLoginEmail(e.target.value)
            }
            autoComplete="email"
          />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ message: "Please input your password!" }]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder="Enter your password"
            autoComplete="current-password"
          />
        </Form.Item>

        {isSignup && (
          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            rules={[{ message: "Please confirm your password!" }]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="Confirm your password"
              autoComplete="new-password"
            />
          </Form.Item>
        )}

        <Form.Item>
          <Button type="primary" htmlType="submit" block className="button">
            {isSignup ? "Signup Now" : "Login Now"}
          </Button>
        </Form.Item>

        <Divider plain className="divider">
          OR
        </Divider>

        <Form.Item>
          <Button
            type="default"
            block
            className="toggle"
            onClick={handleToggle}
          >
            {isSignup ? "Switch to Login" : "Switch to Signup"}
          </Button>
        </Form.Item>
      </Form>

      <div className="image-section">
        <img src="/auth.png" alt="Auth Visual" className="img" />
      </div>
    </div>
  );
}

export default AuthForm;
