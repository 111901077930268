import React, { useState, useEffect } from "react";
import { ConfigProvider } from "antd";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Dashboard from "./Pages/Dashboard/Dashboard";
import PasswordManager from "./Pages/passwordManager/PasswordManager";
import LoadingPage from "./components/Loadingpage/LoadingPage";
import LayoutMain from "./components/layout/LayoutMain";
import Verify from "./Pages/auth/Verify";
import UserManagamentPage from "./Pages/userManagament/UserManagamentPage";
import Apps from "./Pages/apps/Apps";
import Folders from "./Pages/folders/Folders";
import AuthForm from "./Pages/auth/AuthForm";
import Builder from "./Pages/builder/Builder";
import Settings from "./Pages/Settings/index";
import SecureNotes from "./Pages/secureNotes/SecureNotes";
import EmergencyVerify from "./Pages/auth/EmergencyVerify";
import ForgotPassword from "./Pages/auth/ForgotPassword";
import SharedItems from "./Pages/sharedItems/SharedItems";
import Profile from "./Pages/profile/Profile";
import NotFound from "./components/notFound/NotFound";
import ConfigManager from "./Pages/configManager/ConfigManager";

const isTokenValid = (token) => {
  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    if (payload.exp * 1000 < Date.now()) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
};

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token && isTokenValid(token)) {
      setIsAuthenticated(true);
    } else {
      localStorage.removeItem("token");
      setIsAuthenticated(false);
    }

    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#14274E",
          borderRadius: 5,
        },
      }}
    >
      <Router>
        <Routes>
          <Route
            path="/auth"
            element={<AuthForm onLoginSuccess={handleLoginSuccess} />}
          />
          <Route path="/verify" element={<Verify />} />
          <Route path="/emergency-Verify" element={<EmergencyVerify />} />
          <Route path="/Forgot-Password" element={<ForgotPassword />} />

          {/* Protected routes */}
          <Route
            path="/"
            element={
              isAuthenticated ? (
                <LayoutMain>
                  <Dashboard />
                </LayoutMain>
              ) : (
                <Navigate to="/auth" />
              )
            }
          />
          <Route
            path="/dashboard"
            element={
              isAuthenticated ? (
                <LayoutMain>
                  <Dashboard />
                </LayoutMain>
              ) : (
                <Navigate to="/auth" />
              )
            }
          />
          <Route
            path="/apps"
            element={
              isAuthenticated ? (
                <LayoutMain>
                  <Apps />
                </LayoutMain>
              ) : (
                <Navigate to="/auth" />
              )
            }
          />
          <Route
            path="/apps/password-manager"
            element={
              isAuthenticated ? (
                <LayoutMain>
                  <PasswordManager />
                </LayoutMain>
              ) : (
                <Navigate to="/auth" />
              )
            }
          />
          <Route
            path="/config-manager"
            element={
              isAuthenticated ? (
                <LayoutMain>
                  <ConfigManager />
                </LayoutMain>
              ) : (
                <Navigate to="/auth" />
              )
            }
          />
          <Route
            path="/shared-items"
            element={
              isAuthenticated ? (
                <LayoutMain>
                  <SharedItems />
                </LayoutMain>
              ) : (
                <Navigate to="/auth" />
              )
            }
          />
          <Route
            path="/secure-notes"
            element={
              isAuthenticated ? (
                <LayoutMain>
                  <SecureNotes />
                </LayoutMain>
              ) : (
                <Navigate to="/auth" />
              )
            }
          />
          <Route
            path="/user-management"
            element={
              isAuthenticated ? (
                <LayoutMain>
                  <UserManagamentPage />
                </LayoutMain>
              ) : (
                <Navigate to="/auth" />
              )
            }
          />
          <Route
            path="/settings"
            element={
              isAuthenticated ? (
                <LayoutMain>
                  <Settings />
                </LayoutMain>
              ) : (
                <Navigate to="/auth" />
              )
            }
          />
          <Route
            path="/builder"
            element={
              isAuthenticated ? (
                <LayoutMain>
                  <Builder />
                </LayoutMain>
              ) : (
                <Navigate to="/auth" />
              )
            }
          />

          <Route
            path="/apps/:appId"
            element={
              isAuthenticated ? (
                <LayoutMain>
                  <PasswordManager />
                </LayoutMain>
              ) : (
                <Navigate to="/auth" />
              )
            }
          />
          <Route
            path="/apps/:appId/:folderId"
            element={
              isAuthenticated ? (
                <LayoutMain>
                  <Folders />
                </LayoutMain>
              ) : (
                <Navigate to="/auth" />
              )
            }
          />
          <Route
            path="/profile"
            element={
              isAuthenticated ? (
                <LayoutMain>
                  <Profile />
                </LayoutMain>
              ) : (
                <Navigate to="/auth" />
              )
            }
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </ConfigProvider>
  );
};

export default App;
