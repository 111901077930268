import React from "react";
import { Modal, Form, Input, Button, message } from "antd";
import axios from "axios";

const CreateSecretModal = ({
  isModalVisible,
  setIsModalVisible,
  folderId,
  fetchSecrets,
}) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [form] = Form.useForm();

  const handleCreateSecret = async (values) => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `${API_URL}/api/apps-secret/create-secret-by-folder-id`,
        {...values, folderId},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 201) {
        fetchSecrets(token);
        setIsModalVisible(false);
        form.resetFields();
        message.success("Secret created successfully");
      } else {
        message.error("Failed to create secret");
      }
    } catch (error) {
      console.error("Secret oluşturma hatası:", error);
      message.error(error.response?.data?.error || "Failed to create secret");
    }
  };

  return (
    <Modal
      title="Create New Secret"
      open={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      footer={null}
    >
      <Form form={form} onFinish={handleCreateSecret}>
        <Form.Item
          name="name"
          key="name"
          label="Secret Name"
          rules={[
            {
              required: true,
              message: "Please input the name of the secret!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="value"
          key="value"
          label="Secret Value"
          rules={[
            {
              required: true,
              message: "Please input the value of the secret!",
            },
          ]}
        >
          <Input autoComplete="current-password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateSecretModal;
