/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  Typography,
  Space,
  Button,
  Modal,
  Form,
  Input,
  Empty,
  message,
  Dropdown,
  Row,
  Col,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  MoreOutlined,
  SearchOutlined,
  DownOutlined,
  ExportOutlined,
  ImportOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import ShareModal from "../../components/share/ShareModal";
import AppsUpload from "./component/AppsUpload";

const { Title, Text } = Typography;

const initialDataSource = [];

const Apps = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [dataSource, setDataSource] = useState(initialDataSource);
  const [filteredData, setFilteredData] = useState(initialDataSource);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isImportOpen, setIsImportOpen] = useState(false);
  const [editForm] = Form.useForm();
  const [form] = Form.useForm();
  const [editingApp, setEditingApp] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      fetchApps(storedToken);
      fetchUsers(storedToken);
    }
  }, []);

  const fetchApps = async (token) => {
    try {
      const response = await axios.get(`${API_URL}/api/apps/get-apps`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const apps = response.data.apps.map((app) => ({
        ...app,
        key: app.id,
      }));
      setDataSource(apps);
      setFilteredData(apps);
    } catch (error) {
      console.error("Error fetching apps:", error);
    }
  };

  const fetchUsers = async (token) => {
    try {
      await axios.get(`${API_URL}/api/users/get-users`, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const showCreateAppModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleEditCancel = () => {
    setIsEditModalVisible(false);
    editForm.resetFields();
    setEditingApp(null);
  };

  const handleCreateApp = async (values) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${API_URL}/api/apps/create-app`,
        values,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const newApp = response.data.app;
      setDataSource([...dataSource, newApp]);
      setFilteredData([...filteredData, newApp]);

      setIsModalVisible(false);
      form.resetFields();
      message.success("App created successfully");
      navigate(`/apps/${newApp.id}`);
    } catch (error) {
      console.error("Error creating app:", error);
      message.error("Failed to create app");
    }
  };

  const handleEditApp = (app) => {
    setEditingApp(app);
    editForm.setFieldsValue({ name: app.name });
    setIsEditModalVisible(true);
  };

  const handleUpdateApp = async (values) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        `${API_URL}/api/apps/update`,
        { ...values, id: editingApp.id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const updatedApp = response.data.app;
      setDataSource(
        dataSource.map((app) => (app.id === updatedApp.id ? updatedApp : app))
      );
      setFilteredData(
        filteredData.map((app) => (app.id === updatedApp.id ? updatedApp : app))
      );
      setIsEditModalVisible(false);
      editForm.resetFields();
      setEditingApp(null);
      message.success("App updated successfully");
    } catch (error) {
      console.error("Error updating app:", error);
      message.error("Failed to update app");
    }
  };

  const handleDeleteApp = (appId, appName) => {
    Modal.confirm({
      title: `Are you sure you want to delete the app "${appName}"?`,
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        const token = localStorage.getItem("token");
        try {
          await axios.delete(`${API_URL}/api/apps/${appId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setDataSource(dataSource.filter((app) => app.id !== appId));
          setFilteredData(filteredData.filter((app) => app.id !== appId));

          message.success(`App "${appName}" deleted successfully.`);
        } catch (error) {
          console.error("Error deleting app:", error);
          message.error(`Failed to delete the app "${appName}".`);
        }
      },
      onCancel() {
        message.info("Deletion cancelled");
      },
    });
  };

  const handleBulkDelete = () => {
    const selectedAppNames = dataSource
      .filter((app) => selectedRowKeys.includes(app.id))
      .map((app) => app.name)
      .join(", ");

    Modal.confirm({
      title: "Are you sure you want to delete the selected apps?",
      content: (
        <span>
          The following apps will be deleted:{" "}
          <Text strong>{selectedAppNames}</Text>. This action cannot be undone.
        </span>
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        const token = localStorage.getItem("token");
        try {
          await Promise.all(
            selectedRowKeys.map((appId) =>
              axios.delete(`${API_URL}/api/apps/${appId}`, {
                headers: { Authorization: `Bearer ${token}` },
              })
            )
          );
          setDataSource(
            dataSource.filter((app) => !selectedRowKeys.includes(app.id))
          );
          setFilteredData(
            filteredData.filter((app) => !selectedRowKeys.includes(app.id))
          );

          setSelectedRowKeys([]);
          message.success("Selected apps deleted successfully.");
        } catch (error) {
          console.error("Error occurred during bulk deletion:", error);
          message.error("Bulk deletion failed.");
        }
      },
    });
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchValue(value);
    const filteredApps = dataSource.filter((app) =>
      app.name.toLowerCase().includes(value)
    );
    setFilteredData(filteredApps);
  };

  const handleExport = () => {
    const dataToExport = filteredData.map((item) => ({
      "App Name": item.name,
      "Number of Secrets": item.secrets,
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Apps Data");

    const fileName = `apps_data.xlsx`;

    XLSX.writeFile(workbook, fileName);
  };

  const showImportModal = () => {
    setIsImportOpen(true);
  };

  const columns = [
    {
      title: "App name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => <a href={`/apps/${record.id}`}>{text}</a>,
    },
    {
      title: "Number of secrets",
      dataIndex: "secrets",
      key: "secrets",
    },
    {
      title: "shared",
      dataIndex: "shared",
      key: "shared",
    },
    {
      key: "actions",
      render: (text, record) => (
        <Space style={{ display: "flex", justifyContent: "end" }}>
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <Space
                      onClick={() => handleEditApp(record)}
                      style={{ width: "100px" }}
                    >
                      <EditOutlined /> Edit
                    </Space>
                  ),
                },
                {
                  key: "2",
                  label: (
                    <Space
                      onClick={() => handleDeleteApp(record.id, record.name)}
                      style={{ width: "100px", color: "red" }}
                    >
                      <DeleteOutlined /> Delete
                    </Space>
                  ),
                },
              ],
            }}
            placement="bottom"
            arrow
            trigger={["click"]}
          >
            <Button
              type="text"
              style={{
                color: selectedRowKeys.includes(record.key)
                  ? "white"
                  : "inherit",
              }}
            >
              <MoreOutlined style={{ fontSize: "20px" }} />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  return (
    <div style={{ padding: "12px 0" }}>
      <Title level={2}>Apps</Title>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Row gutter={[16, 16]} justify="space-between" align="middle">
          {/* Sol taraftaki butonlar */}
          <Col xs={24} sm={12} md={12} lg={12}>
            <Space direction="horizontal" size="middle" wrap>
              <Button
                icon={<PlusOutlined />}
                onClick={showCreateAppModal}
                type="primary"
              >
                Create App
              </Button>
              <Dropdown
                menu={{
                  items: [
                    {
                      key: "1",
                      label: (
                        <Space
                          style={{ color: "red" }}
                          onClick={handleBulkDelete}
                        >
                          <DeleteOutlined /> Delete
                        </Space>
                      ),
                    },
                  ],
                }}
                trigger={["click"]}
              >
                <Button disabled={selectedRowKeys.length === 0}>
                  Actions <DownOutlined />
                </Button>
              </Dropdown>
            </Space>
          </Col>

          {/* Sağ taraftaki arama ve dropdown */}
          <Col xs={24} sm={12} md={12} lg={12}>
            <Row gutter={[8, 8]} align="middle" justify="end" wrap={false}>
              <Col>
                <Input
                  placeholder="Search..."
                  prefix={<SearchOutlined />}
                  value={searchValue}
                  onChange={handleSearch}
                  style={{ width: "100%", maxWidth: 200 }}
                />
              </Col>
              <Col>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: (
                          <Space onClick={showImportModal}>
                            <ImportOutlined /> Import
                          </Space>
                        ),
                      },
                      {
                        key: "2",
                        label: (
                          <Space onClick={handleExport}>
                            <ExportOutlined /> Export
                          </Space>
                        ),
                      },
                    ],
                  }}
                  placement="bottomRight"
                  arrow
                  trigger={["click"]}
                >
                  <Button type="text">
                    <MoreOutlined style={{ fontSize: "20px" }} />
                  </Button>
                </Dropdown>
              </Col>
            </Row>
          </Col>
        </Row>

        {filteredData.length === 0 ? (
          <Empty description="No apps available" />
        ) : (
          <Table
            dataSource={filteredData}
            columns={columns}
            pagination={false}
            rowSelection={rowSelection}
            scroll={{ x: "max-content" }}
          />
        )}
      </Space>
      <Modal
        title="Create New App"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} onFinish={handleCreateApp}>
          <Form.Item
            name="name"
            label="App Name"
            rules={[
              { required: true, message: "Please input the name of the app!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Edit App"
        open={isEditModalVisible}
        onCancel={handleEditCancel}
        footer={null}
      >
        <Form form={editForm} onFinish={handleUpdateApp}>
          <Form.Item
            name="name"
            label="App Name"
            rules={[
              { required: true, message: "Please input the name of the app!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <AppsUpload
        isImportOpen={isImportOpen}
        setIsImportOpen={setIsImportOpen}
        name="Apps İmport"
      />
      <ShareModal
        isModalVisible={isShareModalVisible}
        setIsModalVisible={setIsShareModalVisible}
      />
    </div>
  );
};

export default Apps;
