/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Modal, Input, Select, Form, Button, message } from "antd";
import axios from "axios";

const { TextArea } = Input;

const SecureNoteNewModal = ({
  isModalVisible,
  setIsModalVisible,
  onNoteCreated,
}) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [form] = Form.useForm();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken && isModalVisible) {
      fetchApps(storedToken);
    }
  }, [isModalVisible]);

  const fetchApps = async (token) => {
    try {
      const response = await axios.get(`${API_URL}/api/apps/get-apps`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const apps = response.data.apps || [];

      // Add "None" option for backend null value
      const formattedOptions = [
        { label: "None", value: null },
        ...apps.map((app) => ({
          label: app.name,
          value: app.id,
        })),
      ];

      setOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching apps:", error);
      message.error("Failed to fetch apps");
    }
  };

  const handleCreateSecureNote = async () => {
    try {
      const token = localStorage.getItem("token");
      const values = await form.validateFields();

      const payload = {
        title: values.title,
        note: values.note,
        app_id: values.app, // If "None" is selected, this will be null
      };

      await axios.post(`${API_URL}/api/secure-note/add-secure-note`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      form.resetFields();
      setIsModalVisible(false);
      if (onNoteCreated) {
        onNoteCreated();
      }
      message.success("Secure note created successfully");
    } catch (error) {
      console.error("Error creating secure note:", error?.response?.data);
      message.error("Failed to create secure note");
    }
  };

  return (
    <Modal
      title="New Note"
      open={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      footer={[
        <Button key="cancel" onClick={() => setIsModalVisible(false)}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleCreateSecureNote}>
          Save
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: "Please enter title!" }]}
        >
          <Input placeholder="Enter title" />
        </Form.Item>
        <Form.Item
          label="Note"
          name="note"
          rules={[{ required: true, message: "Please enter a note!" }]}
        >
          <TextArea rows={4} placeholder="Enter note" />
        </Form.Item>
        <Form.Item label="Select Apps" name="app" style={{ width: "320px" }}>
          <Select allowClear placeholder="Select apps" options={options} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SecureNoteNewModal;
