/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  Typography,
  Space,
  Button,
  Empty,
  message,
  Modal,
  Form,
  Input,
  Dropdown,
  Row,
  Col,
} from "antd";
import {
  TeamOutlined,
  EditOutlined,
  DeleteOutlined,
  UserOutlined,
  MoreOutlined,
  DownOutlined,
} from "@ant-design/icons";
import TeamUsers from "./TeamUsers";

const { Title } = Typography;

const TeamManagement = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [teams, setTeams] = useState([]);
  const [userId, setUserId] = useState(null);
  const [isCreateTeamModalVisible, setIsCreateTeamModalVisible] =
    useState(false);
  const [isEditTeamModalVisible, setIsEditTeamModalVisible] = useState(false);
  const [editingTeam, setEditingTeam] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectionType] = useState("checkbox");

  const [form] = Form.useForm();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      setUserId(decodedToken.id);
      fetchTeams(decodedToken.id, token);
    }
  }, []);

  const fetchTeams = async (userId, token) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/teams/get-teams-by-user-id`,
        { userId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setTeams(response.data.teams);
    } catch (error) {
      console.error("Error fetching teams:", error);
      message.error("Takımları getirirken bir hata oluştu.");
    }
  };

  const showCreateTeamModal = () => {
    setIsCreateTeamModalVisible(true);
  };

  const handleCreateTeamCancel = () => {
    setIsCreateTeamModalVisible(false);
    form.resetFields();
  };

  const handleCreateTeam = async (values) => {
    const token = localStorage.getItem("token");
    if (!userId) return;

    try {
      await axios.post(`${API_URL}/api/teams/create-team`, values, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchTeams(userId, token);
      setIsCreateTeamModalVisible(false);
      form.resetFields();
      message.success("Takım başarıyla oluşturuldu.");
    } catch (error) {
      console.error("Takım oluşturulurken bir hata oluştu:", error);
      message.error("Takım oluşturulurken bir hata oluştu.");
    }
  };

  const showEditTeamModal = (team) => {
    setEditingTeam(team);
    form.setFieldsValue({ name: team.name });
    setIsEditTeamModalVisible(true);
  };

  const handleEditTeamCancel = () => {
    setIsEditTeamModalVisible(false);
    setEditingTeam(null);
    form.resetFields();
  };

  const handleEditTeam = async (values) => {
    const token = localStorage.getItem("token");
    if (!editingTeam) return;

    try {
      await axios.put(
        `${API_URL}/api/teams/update-team`,
        { ...values, teamId: editingTeam.id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      fetchTeams(userId, token);
      setIsEditTeamModalVisible(false);
      setEditingTeam(null);
      form.resetFields();
      message.success("Takım başarıyla güncellendi.");
    } catch (error) {
      console.error("Takım güncellenirken bir hata oluştu:", error);
      message.error("Takım güncellenirken bir hata oluştu.");
    }
  };

  const handleDeleteTeam = async (teamId) => {
    const token = localStorage.getItem("token");

    try {
      await axios.post(
        `${API_URL}/api/teams/delete-team`,
        { teamId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      fetchTeams(userId, token);
      message.success("Takım başarıyla silindi.");
    } catch (error) {
      console.error("Takım silinirken bir hata oluştu:", error);
      message.error("Takım silinirken bir hata oluştu.");
    }
  };

  const handleToggleTeamUsers = (team) => {
    setSelectedTeam((prev) => (prev && prev.id === team.id ? null : team));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const columns = [
    {
      title: "Team Name",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <Space>
          <TeamOutlined />
          {text}
        </Space>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space style={{ display: "flex", justifyContent: "end" }}>
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <Space
                      onClick={() => showEditTeamModal(record)}
                      style={{ width: "100px" }}
                    >
                      <EditOutlined /> Edit
                    </Space>
                  ),
                },
                {
                  key: "2",
                  label: (
                    <Space
                      onClick={() => handleToggleTeamUsers(record)}
                      style={{ width: "100px" }}
                    >
                      <UserOutlined /> Add User
                    </Space>
                  ),
                },
                {
                  key: "3",
                  label: (
                    <Space
                      onClick={() => handleDeleteTeam(record.id)}
                      type="primary"
                      danger
                      style={{
                        width: "100px",
                        color: "red",
                      }}
                    >
                      <DeleteOutlined /> Delete
                    </Space>
                  ),
                },
              ],
            }}
            placement="bottom"
            arrow
            trigger={["click"]}
          >
            <Button type="text">
              <MoreOutlined
                style={{
                  fontSize: "20px",
                  color: selectedRowKeys.includes(record.id)
                    ? "#fff"
                    : "inherit",
                }}
              />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Title level={2}>Team Managament</Title>
      <Row gutter={[16, 16]} justify="space-between" align="middle">
        {/* Create Team Butonu */}
        <Col xs={24} sm="auto">
          <Button
            type="primary"
            onClick={showCreateTeamModal}
            disabled={!userId}
            style={{ marginRight: 10, marginBottom: 10 }}
          >
            Create Team
          </Button>

          <Dropdown
            disabled={selectedRowKeys.length === 0}
            menu={{
              items: [
                {
                  label: (
                    <Space style={{ color: "red" }}>
                      <DeleteOutlined /> Delete
                    </Space>
                  ),
                },
              ],
            }}
            placement="bottom"
            arrow
            trigger={["click"]}
          >
            <Button onClick={(e) => e.preventDefault()}>
              <Space>
                Actions <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Col>
      </Row>
      {teams.length === 0 ? (
        <Empty description="No Team" />
      ) : (
        <Table
          dataSource={teams}
          columns={columns}
          pagination={false}
          rowKey="id"
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
          scroll={{ x: "max-content" }}
        />
      )}
      {selectedTeam && <TeamUsers team={selectedTeam} />}{" "}
      <Modal
        title="Create Team"
        open={isCreateTeamModalVisible}
        onCancel={handleCreateTeamCancel}
        footer={null}
      >
        <Form form={form} onFinish={handleCreateTeam}>
          <Form.Item
            name="name"
            label="Team Name"
            rules={[
              {
                required: true,
                message: "Please enter team name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Edit Team"
        open={isEditTeamModalVisible}
        onCancel={handleEditTeamCancel}
        footer={null}
      >
        <Form form={form} onFinish={handleEditTeam}>
          <Form.Item
            name="name"
            label="Team Name"
            rules={[
              {
                required: true,
                message: "Please enter team name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TeamManagement;
