/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  Typography,
  Space,
  Button,
  Input,
  Empty,
  Dropdown,
  Row,
  Col,
  Modal,
  Form,
  message,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  MoreOutlined,
  SearchOutlined,
  DownOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./index.css";

const { Title, Text } = Typography;
const initialDataSource = [];

const ConfigManager = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [dataSource, setDataSource] = useState(initialDataSource);
  const [filteredData, setFilteredData] = useState(initialDataSource);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [viewEnv, setViewEnv] = useState(null);
  const [editingEnv, setEditingEnv] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [createForm] = Form.useForm();
  const [editForm] = Form.useForm();

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      fetchEnvFiles(storedToken);
    }
  }, []);

  const fetchEnvFiles = async (token) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/config-manager/get-env-files`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const envFiles = response.data.envFiles.map((file) => ({
        ...file,
        key: file.id,
      }));
      setDataSource(envFiles);
      setFilteredData(envFiles);
    } catch (error) {
      console.error("Error fetching env files:", error);
      message.error("Failed to load config data.");
    }
  };

  const handleCreateEnv = async (values) => {
    const token = localStorage.getItem("token");
    if (!token) return;

    const lines = values?.envFileContent?.split('\n');
    const result = {};
    lines.forEach((line) => {
      const [key, value] = line.split("=");
      result[key] = value
    })
    
    try {
      const response = await axios.post(
        `${API_URL}/api/config-manager/add-env-file`,
        {
          envFileName: values.envFileName,
          envFile: result || {},
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      message.success(response.data.message || "Env file created successfully");

      setIsModalVisible(false);
      createForm.resetFields();

      fetchEnvFiles(token);
    } catch (error) {
      console.error("Error creating env file:", error);
      message.error("Failed to create env file");
    }
  };

  const handleEditEnv = (record) => {
    setEditingEnv(record);

    let fileContent = {};
    try {
      fileContent = getCleanContent(record.env_file);
    } catch {
      fileContent = record.env_file;
    }

    editForm.setFieldsValue({
      envFileName: record.env_file_name,
      envFileContent: fileContent,
    });
    setIsEditModalVisible(true);
  };

  const handleUpdateEnv = async (values) => {
    const token = localStorage.getItem("token");
    if (!token || !editingEnv) return;

    const lines = values?.envFileContent?.split('\n');
    const result = {};
    lines.forEach((line) => {
      const [key, value] = line.split("=");
      result[key] = value
    })

    try {
      await axios.put(
        `${API_URL}/api/config-manager/update-env-file`,
        {
          envFileId: editingEnv.id,
          envFileName: values.envFileName,
          envFile: result || {},
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      message.success("Env file updated successfully");

      setIsEditModalVisible(false);
      editForm.resetFields();
      setEditingEnv(null);

      fetchEnvFiles(token);
    } catch (error) {
      console.error("Error updating env file:", error);
      message.error("Failed to update env file");
    }
  };

  const handleDeleteEnv = (envFileId, envFileName) => {
    Modal.confirm({
      title: `Are you sure you want to delete "${envFileName}"?`,
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        const token = localStorage.getItem("token");
        if (!token) return;

        try {
          await axios.post(
            `${API_URL}/api/config-manager/delete-env-file`,
            { envFileId },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          message.success(`"${envFileName}" deleted successfully.`);
          fetchEnvFiles(token);
        } catch (error) {
          console.error("Error deleting env file:", error);
          message.error(`Failed to delete "${envFileName}".`);
        }
      },
      onCancel() {
        message.info("Deletion cancelled");
      },
    });
  };

  const handleBulkDelete = () => {
    const selectedFiles = dataSource.filter((item) =>
      selectedRowKeys.includes(item.id)
    );
    const selectedNames = selectedFiles
      .map((item) => item.env_file_name)
      .join(", ");

    Modal.confirm({
      title: "Are you sure you want to delete the selected env files?",
      content: (
        <span>
          The following items will be deleted:{" "}
          <Text strong>{selectedNames}</Text>. This action cannot be undone.
        </span>
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        const token = localStorage.getItem("token");
        if (!token) return;

        try {
          await Promise.all(
            selectedRowKeys.map((envFileId) =>
              axios.post(
                `${API_URL}/config-manager/delete-env-file`,
                { envFileId },
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              )
            )
          );
          message.success("Selected env files deleted successfully.");
          setSelectedRowKeys([]);
          fetchEnvFiles(token);
        } catch (error) {
          console.error("Error occurred during bulk deletion:", error);
          message.error("Bulk deletion failed.");
        }
      },
    });
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchValue(value);
    const filtered = dataSource.filter((item) =>
      item.env_file_name.toLowerCase().includes(value)
    );
    setFilteredData(filtered);
  };

  const openCreateModal = () => {
    setIsModalVisible(true);
  };
  const closeCreateModal = () => {
    setIsModalVisible(false);
    createForm.resetFields();
  };
  const closeEditModal = () => {
    setIsEditModalVisible(false);
    editForm.resetFields();
    setEditingEnv(null);
  };

  const handleViewEnv = (record) => {    
    setViewEnv(record);
    setIsViewModalVisible(true);
  };

  const closeViewModal = () => {
    setIsViewModalVisible(false);
    setViewEnv(null);
  };

  /**
   * Helper function to remove leading/trailing quotes (if present)
   * and convert any escaped sequences like \n or \"
   */
  const getCleanContent = (content = {}) => {
    const parsedContent = JSON.parse(content);
    
    // let cleaned = parsedContent.trim();
    // // Remove leading and trailing quotes if they exist
    // cleaned = cleaned.replace(/^"(.*)"$/, "$1");
    // // Replace escaped newlines with actual newlines
    // cleaned = cleaned.replace(/\\n/g, "\n");
    // // Replace escaped quotes with normal quotes
    // cleaned = cleaned.replace(/\\"/g, '"');

    const envContent = Object.entries(parsedContent)
      .map(([key, value]) => `${key}=${value}`)
      .join("\n");

    return envContent;
  };

  const columns = [
    {
      title: "Env File Name",
      dataIndex: "env_file_name",
      key: "env_file_name",
      render: (text, record) => (
        <Link onClick={() => handleViewEnv(record)}>{text}</Link>
      ),
    },
    {
      title: "API Key",
      dataIndex: "api_key",
      key: "api_key",
      render: (apiKey) => {
        if (!apiKey) return null;

        const maskedKey =
          apiKey.length > 8 ? apiKey.slice(0, 8) + "**************" : apiKey;

        return (
          <Space>
            <span>{maskedKey}</span>
            <Button
              icon={<CopyOutlined />}
              size="small"
              onClick={() => {
                navigator.clipboard.writeText(apiKey).then(
                  () => {
                    message.success("API key copied to clipboard!");
                  },
                  (err) => {
                    message.error("Failed to copy!");
                    console.error(err);
                  }
                );
              }}
            />
          </Space>
        );
      },
    },
    {
      key: "actions",
      render: (text, record) => (
        <Space style={{ display: "flex", justifyContent: "end" }}>
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <Space
                      onClick={() => handleEditEnv(record)}
                      style={{ width: "100px" }}
                    >
                      <EditOutlined /> Edit
                    </Space>
                  ),
                },
                {
                  key: "2",
                  label: (
                    <Space
                      onClick={() =>
                        handleDeleteEnv(record.id, record.env_file_name)
                      }
                      style={{ width: "100px", color: "red" }}
                    >
                      <DeleteOutlined /> Delete
                    </Space>
                  ),
                },
              ],
            }}
            placement="bottom"
            arrow
            trigger={["click"]}
          >
            <Button type="text">
              <MoreOutlined style={{ fontSize: "20px" }} />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => setSelectedRowKeys(selectedKeys),
  };

  return (
    <div style={{ padding: "12px 0" }}>
      <Title level={2}>Config Manager (Env Files)</Title>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Row gutter={[16, 16]} justify="space-between" align="middle">
          {/* Left side buttons */}
          <Col xs={24} sm={12} md={12} lg={12}>
            <Space direction="horizontal" size="middle" wrap>
              <Button
                icon={<PlusOutlined />}
                onClick={openCreateModal}
                type="primary"
              >
                Create Env File
              </Button>
              <Dropdown
                menu={{
                  items: [
                    {
                      key: "1",
                      label: (
                        <Space
                          style={{ color: "red" }}
                          onClick={handleBulkDelete}
                        >
                          <DeleteOutlined /> Delete
                        </Space>
                      ),
                    },
                  ],
                }}
                trigger={["click"]}
              >
                <Button disabled={selectedRowKeys.length === 0}>
                  Actions <DownOutlined />
                </Button>
              </Dropdown>
            </Space>
          </Col>

          <Col xs={24} sm={12} md={12} lg={12}>
            <Row gutter={[8, 8]} align="middle" justify="end" wrap={false}>
              <Col>
                <Input
                  placeholder="Search..."
                  prefix={<SearchOutlined />}
                  value={searchValue}
                  onChange={handleSearch}
                  style={{ width: "100%", maxWidth: 200 }}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        {filteredData.length === 0 ? (
          <Empty description="No env files available" />
        ) : (
          <Table
            columns={columns}
            dataSource={filteredData}
            pagination={false}
            rowSelection={rowSelection}
            scroll={{ x: "max-content" }}
          />
        )}
      </Space>

      {/* Create Modal */}
      <Modal
        title="Create Env File"
        open={isModalVisible}
        onCancel={closeCreateModal}
        footer={null}
      >
        <Form form={createForm} onFinish={handleCreateEnv} layout="vertical">
          <Form.Item
            name="envFileName"
            label="Env File Name"
            rules={[{ required: true, message: "Please enter env file name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="envFileContent" label="Env File Content">
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Edit Modal */}
      <Modal
        title="Edit Env File"
        open={isEditModalVisible}
        onCancel={closeEditModal}
        footer={null}
      >
        <Form form={editForm} onFinish={handleUpdateEnv} layout="vertical">
          <Form.Item
            name="envFileName"
            label="Env File Name"
            rules={[{ required: true, message: "Please enter env file name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="envFileContent"
            label="Env File Content"
            tooltip="You can insert JSON or text content here"          
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* View Modal */}
      <Modal
        title={`Env File: ${viewEnv?.env_file_name}`}
        open={isViewModalVisible}
        onCancel={closeViewModal}
        width={820}
        footer={[
          <Button
            key="copy"
            type="primary"
            onClick={() => {
              if (viewEnv?.env_file) {
                const cleanContent = getCleanContent(viewEnv.env_file);
                navigator.clipboard.writeText(cleanContent).then(
                  () => {
                    message.success("Env File Content copied to clipboard!");
                  },
                  (err) => {
                    message.error("Failed to copy content!");
                    console.error(err);
                  }
                );
              } else {
                message.warning("No content available to copy!");
              }
            }}
          >
            Copy Content
          </Button>,
          <Button key="close" onClick={closeViewModal}>
            Close
          </Button>,
        ]}
      >
        {viewEnv && (
          <div>
            <pre className="view-env">{getCleanContent(viewEnv.env_file)}</pre>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ConfigManager;
