/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Form, Input, Button, message, Typography } from "antd";
import axios from "axios";
import "./AuthForm.css";

const { Title } = Typography;

const ForgotPassword = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      message.error("Authorization error. Please log in.");
      return;
    }

    try {
      const response = await axios.get(`${API_URL}/api/users/get-users`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      message.error("An error occurred while retrieving user data.");
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    const userExists = users.some((user) => user.email === values.email);

    if (!userExists) {
      message.error("The e-mail address was not found in the system.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `${API_URL}/api/users/change-password`,
        {
          email: values.email,
        }
      );

      if (response.status === 200) {
        message.success("A password reset email has been sent.");
        setEmailSent(true);
      }
    } catch (error) {
      message.error("An error has occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="verification-form">
        <div className="logo">
          <img src="/logo2.png" alt="Logo" width={120} />
        </div>

        <Title level={3} className="title">
          Forgot Your Password?
        </Title>

        <Form name="forgot-password" onFinish={onFinish} layout="vertical">
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter your email address!",
              },
              { type: "email", message: "Enter a valid email!" },
            ]}
          >
            <Input placeholder="Your email address" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              block
              className="button-verify"
            >
              {emailSent ? "Resend" : "Send"}
            </Button>
          </Form.Item>
        </Form>
      </div>

      <div className="image-section">
        <img src="/forgot.png" alt="Auth Visual" className="img-verify" />
      </div>
    </div>
  );
};

export default ForgotPassword;
