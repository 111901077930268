import React, { useState } from "react";
import { Layout, Menu } from "antd";
import "./index.css";
import PasswordChange from "./passwordChange/PasswordChange";

const { Sider, Content } = Layout;

const Settings = () => {
  const [selectedMenu, setSelectedMenu] = useState("basic-settings");

  const handleMenuClick = (e) => {
    setSelectedMenu(e.key);
  };

  const renderContent = () => {
    switch (selectedMenu) {
      case "password-change":
        return <PasswordChange />;
      //   case "email-settings":
      //     return <div>Email Settings Content</div>;
      default:
        return <PasswordChange />;
    }
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
        background: "#fff",
        margin: "0 -24px",
        borderRadius: "6px",
      }}
    >
      <Sider width={250} style={{ background: "#f5f5f5", marginTop: "5px" }}>
        <Menu
          mode="inline"
          defaultSelectedKeys={["password-change"]}
          style={{ height: "100%", borderRadius: "6px" }}
          onClick={handleMenuClick}
          className="settings-menu"
        >
          <Menu.Item key="password-change" className="settings-menu-item">
            Change Password
          </Menu.Item>
          {/* <Menu.Item key="api" className="settings-menu-item">
            API
          </Menu.Item> */}
        </Menu>
      </Sider>
      <Content style={{ padding: "24px" }}>{renderContent()}</Content>
    </Layout>
  );
};

export default Settings;
