import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input, Typography } from "antd";
import "./AuthForm.css";

const { Title } = Typography;

const EmergencyVerify = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [verificationCode, setVerificationCode] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const navigate = useNavigate();

  const handleInputChange = (index, value) => {
    if (!/^\d*$/.test(value)) return;
    const newCode = [...verificationCode];
    newCode[index] = value;
    setVerificationCode(newCode);

    if (value && index < 5) {
      document.getElementById(`input-${index + 1}`).focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !verificationCode[index]) {
      if (index > 0) {
        document.getElementById(`input-${index - 1}`).focus();
      }
    }
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    const code = verificationCode.join("");

    if (code.length !== 6) {
      setErrorMessage("Please enter a 6-digit emergency code.");
      return;
    }

    try {
      const response = await fetch(`${API_URL}/api/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ emergencyCode: code }),
      });

      if (response.ok) {
        const data = await response.json();

        localStorage.setItem("token", data.token);
        localStorage.setItem("userEmail", data.email);

        setSuccessMessage("Login successful!");
        setErrorMessage(null);

        navigate("/dashboard");
      } else {
        const data = await response.json();
        setErrorMessage(data.error || "Verification failed.");
        setSuccessMessage(null);
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
      setSuccessMessage(null);
    }
  };

  return (
    <div className="container">
      <form className="verification-form" onSubmit={handleVerify}>
        <div className="logo">
          <img src="/logo3.png" alt="Logo" width={120} />
        </div>

        <Title level={3} className="title">
          Emergency Code
        </Title>

        <div className="verification-inputs">
          {verificationCode.map((digit, index) => (
            <Input
              key={index}
              id={`input-${index}`}
              type="text"
              maxLength="1"
              value={digit}
              onChange={(e) => handleInputChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              className="verification-input"
            />
          ))}
        </div>

        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}

        <Button
          type="primary"
          htmlType="submit"
          block
          className="button-verify"
        >
          Emergency Login
        </Button>
      </form>

      <div className="image-section">
        <img src="/emergency.png" alt="Auth Visual" className="img-verify" />
      </div>
    </div>
  );
};

export default EmergencyVerify;
