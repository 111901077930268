/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Tabs,
  Input,
  Button,
  Checkbox,
  Radio,
  Typography,
  Form,
  Space,
  Tooltip,
  Select,
  message,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { faker } from "@faker-js/faker";
import axios from "axios";
import "./Builder.css";

const { Title } = Typography;

function Builder() {
  // Password Kısmı
  const [passwordType, setPasswordType] = useState("password");
  const [wordCount, setWordCount] = useState(3);
  const [separator, setSeparator] = useState("-");
  const [length, setLength] = useState(14);
  const [minNumbers, setMinNumbers] = useState(0);
  const [minSpecialChars, setMinSpecialChars] = useState(0);
  const [includeUpperCase, setIncludeUpperCase] = useState(true);
  const [includeLowerCase, setIncludeLowerCase] = useState(true);
  const [includeNumbers, setIncludeNumbers] = useState(false);
  const [includeSymbols, setIncludeSymbols] = useState(false);
  const [useUppercase, setUseUppercase] = useState(false);
  const [addNumber, setAddNumber] = useState(false);
  const [password, setPassword] = useState("");

  const generatePassword = () => {
    let generatedPassword = "";

    if (passwordType === "password") {
      let charset = "";
      let numbers = "";
      let symbols = "";
      if (includeUpperCase) charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      if (includeLowerCase) charset += "abcdefghijklmnopqrstuvwxyz";
      if (includeNumbers) numbers = "0123456789";
      if (includeSymbols) symbols = "!@#$%^&*()_+~`|}{[]:;?><,./-=";

      for (let i = 0; i < minNumbers; i++) {
        generatedPassword += numbers.charAt(
          Math.floor(Math.random() * numbers.length)
        );
      }

      for (let i = 0; i < minSpecialChars; i++) {
        generatedPassword += symbols.charAt(
          Math.floor(Math.random() * symbols.length)
        );
      }

      const combinedCharset = charset + numbers + symbols;
      for (
        let i = generatedPassword.length, n = combinedCharset.length;
        i < length;
        ++i
      ) {
        generatedPassword += combinedCharset.charAt(
          Math.floor(Math.random() * n)
        );
      }

      generatedPassword = generatedPassword
        .split("")
        .sort(() => 0.5 - Math.random())
        .join("");
    } else if (passwordType === "passphrase") {
      const words = [];
      const randomIndex = Math.floor(Math.random() * wordCount);

      for (let i = 0; i < wordCount; i++) {
        let word = faker.word.noun();
        if (useUppercase) {
          word = word.charAt(0).toUpperCase() + word.slice(1);
        }
        if (addNumber && i === randomIndex) {
          const randomNumber = Math.floor(Math.random() * 100);
          word = `${word}${randomNumber}`;
        }
        words.push(word);
      }
      generatedPassword = words.join(separator);
    }

    setPassword(generatedPassword);
  };

  const handleLengthChange = (e) => {
    const value = Math.min(50, e.target.value);
    setLength(value);
  };

  const handleIncludeNumbersChange = (e) => {
    const isChecked = e.target.checked;
    setIncludeNumbers(isChecked);
    if (!isChecked) {
      setMinNumbers(0);
    } else {
      setMinNumbers(1);
    }
  };

  const handleIncludeSymbolsChange = (e) => {
    const isChecked = e.target.checked;
    setIncludeSymbols(isChecked);
    if (!isChecked) {
      setMinSpecialChars(0);
    } else {
      setMinSpecialChars(1);
    }
  };

  const handleMinNumbersChange = (e) => {
    const value = Math.min(
      parseInt(e.target.value, 10),
      length - 1 - minSpecialChars
    );
    setMinNumbers(value);
  };

  const handleMinSpecialCharsChange = (e) => {
    const value = Math.min(
      parseInt(e.target.value, 10),
      length - 1 - minNumbers
    );
    setMinSpecialChars(value);
  };

  // Username Kısmı
  const [username, setUsername] = useState("");
  const [usernameType, setUsernameType] = useState("randomWord");
  const [email, setEmail] = useState("");
  const [domainInput, setDomainInput] = useState("");
  const [maskedEmailService, setMaskedEmailService] = useState("");
  const [aliasInput, setAliasInput] = useState("");
  const [apiAccessToken, setApiAccessToken] = useState("");

  const generateRandomString = (length = 8) => {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const number = "0123456789";
    const combinedCharset = charset + number;
    let randomString = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * combinedCharset.length);
      randomString += combinedCharset[randomIndex];
    }

    return randomString;
  };

  const generatePlusEmailUsername = () => {
    if (!email.includes("@")) {
      message.error("Lütfen geçerli bir email adresi girin!");
      setUsername("-");
      return;
    }

    const [namePart, domainPart] = email.split("@");
    const randomWord = generateRandomString(5);
    const newUsername = `${namePart}+${randomWord}@${domainPart}`;

    setUsername(newUsername);
  };

  const generateCatchAllUsername = () => {
    if (domainInput.trim()) {
      const randomWord = generateRandomString(5);
      const newUsername = `${randomWord}@${domainInput}`;
      setUsername(newUsername);
    } else {
      message.error("Lütfen bir alan adı girin!");
      setUsername("-");
    }
  };

  const validateService = async () => {
    try {
      if (maskedEmailService === "firefoxRelay") {
        const response = await axios.get(
          "https://api.firefoxrelay.com/v1/verify",
          {
            headers: { Authorization: `Bearer ${apiAccessToken}` },
          }
        );

        return response.status === 200;
      }

      // Diğer servisler için benzer doğrulama mantığını burada ekleyebilirsiniz
    } catch (error) {
      message.error("Servis sağlayıcı doğrulaması sırasında bir hata oluştu.");
      return false;
    }
  };

  const generateMaskedEmailUsername = async () => {
    const isValid = await validateService();

    if (!isValid) {
      message.error(
        "Geçersiz servis sağlayıcı bilgileri. Lütfen doğru bilgileri girin."
      );
      setUsername("-");
      return;
    }

    const randomWord = generateRandomString(5);
    const newUsername = `${randomWord}@firefoxrelay.com`;

    setUsername(newUsername);
  };

  const handleGenerateUsername = async () => {
    if (usernameType === "maskedEmail") {
      await generateMaskedEmailUsername();
      return;
    }

    if (usernameType === "catchAll" && !domainInput.trim()) {
      message.error("Lütfen bir alan adı girin!");
      setUsername("-");
      return;
    }

    if (
      usernameType === "plusEmail" &&
      (!email.includes("@") || !email.trim())
    ) {
      message.error("Lütfen geçerli bir email adresi girin!");
      setUsername("-");
      return;
    }

    generateUsername();
  };

  const generateUsername = () => {
    switch (usernameType) {
      case "plusEmail":
        generatePlusEmailUsername();
        break;
      case "catchAll":
        generateCatchAllUsername();
        break;
      case "maskedEmail":
        generateMaskedEmailUsername();
        break;
      default:
        let newUsername = faker.word.noun();
        if (addNumber) {
          newUsername += Math.floor(Math.random() * 9000 + 1000);
        }
        if (useUppercase) {
          newUsername =
            newUsername.charAt(0).toUpperCase() + newUsername.slice(1);
        }
        setUsername(newUsername);
        break;
    }
  };

  useEffect(() => {
    if (usernameType !== "maskedEmail") {
      generateUsername();
    }
    generatePassword();
    const userEmail = localStorage.getItem("userEmail");
    if (userEmail) {
      setEmail(userEmail);
    }
  }, [usernameType, useUppercase]);

  const items = [
    {
      key: "1",
      label: "Password",
      children: (
        <div>
          <Title level={2}>Password Generator</Title>
          <Input
            value={password}
            readOnly
            size="large"
            style={{ marginBottom: "16px", width: "600px" }}
          />

          <Form layout="vertical" className="forms">
            <Form.Item label="Password type">
              <Radio.Group
                defaultValue="password"
                onChange={(e) => setPasswordType(e.target.value)}
                value={passwordType}
              >
                <Radio value="password">Password</Radio>
                <Radio value="passphrase">Long word</Radio>
              </Radio.Group>
            </Form.Item>

            {passwordType === "password" && (
              <>
                <Form.Item label="Length">
                  <Input
                    type="number"
                    value={length}
                    onChange={handleLengthChange}
                    max={50}
                  />
                </Form.Item>
                <Form.Item label="Min password length">
                  <Input value={length} readOnly disabled />
                </Form.Item>
                <Form.Item label="Least number">
                  <Input
                    type="number"
                    value={minNumbers}
                    onChange={handleMinNumbersChange}
                    max={length - 1 - minSpecialChars}
                    disabled={!includeNumbers}
                  />
                </Form.Item>
                <Form.Item label="Min special characters">
                  <Input
                    type="number"
                    value={minSpecialChars}
                    onChange={handleMinSpecialCharsChange}
                    max={length - 1 - minNumbers}
                    disabled={!includeSymbols}
                  />
                </Form.Item>
                <Form.Item label="Options">
                  <Checkbox
                    checked={includeUpperCase}
                    onChange={(e) => setIncludeUpperCase(e.target.checked)}
                  >
                    A-Z
                  </Checkbox>
                  <Checkbox
                    checked={includeLowerCase}
                    onChange={(e) => setIncludeLowerCase(e.target.checked)}
                  >
                    a-z
                  </Checkbox>
                  <Checkbox
                    checked={includeNumbers}
                    onChange={handleIncludeNumbersChange}
                  >
                    0-9
                  </Checkbox>
                  <Checkbox
                    checked={includeSymbols}
                    onChange={handleIncludeSymbolsChange}
                  >
                    !@#$%^&*
                  </Checkbox>
                </Form.Item>
              </>
            )}

            {passwordType === "passphrase" && (
              <>
                <Form.Item label="Kelime sayısı">
                  <Input
                    type="number"
                    value={wordCount}
                    onChange={(e) => setWordCount(e.target.value)}
                  />
                </Form.Item>
                <Form.Item label="Kelime ayırıcı">
                  <Input
                    value={separator}
                    onChange={(e) => setSeparator(e.target.value)}
                  />
                </Form.Item>
                <Form.Item label="Options">
                  <Checkbox
                    checked={useUppercase}
                    onChange={(e) => setUseUppercase(e.target.checked)}
                  >
                    Capitalize initials
                  </Checkbox>
                  <Checkbox
                    checked={addNumber}
                    onChange={(e) => setAddNumber(e.target.checked)}
                  >
                    Add number
                  </Checkbox>
                </Form.Item>
              </>
            )}
          </Form>

          <div className="button-container">
            <Button type="primary" onClick={generatePassword}>
              Create New Password
            </Button>
            <Button onClick={() => navigator.clipboard.writeText(password)}>
              Copy Password
            </Button>
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: "Username",
      children: (
        <div>
          <Title level={2}>Username Generator</Title>
          <Input
            value={username}
            readOnly
            size="large"
            style={{ marginBottom: "16px", width: "600px" }}
          />

          <Form layout="vertical" className="userForm">
            <Form.Item label="Username type">
              <Radio.Group
                onChange={(e) => setUsernameType(e.target.value)}
                value={usernameType}
              >
                <Space direction="vertical" className="usersForm-radio">
                  <Radio value="plusEmail">
                    Plus addressing email
                    <Tooltip title="Use your email provider’s plus addressing features.">
                      <InfoCircleOutlined style={{ marginLeft: 8 }} />
                    </Tooltip>
                  </Radio>
                  <Radio value="catchAll">
                    Catch-all email
                    <Tooltip title="Use your domain’s catch-all email address.">
                      <InfoCircleOutlined style={{ marginLeft: 8 }} />
                    </Tooltip>
                  </Radio>
                  <Radio value="randomWord">Random word</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>

            {usernameType === "randomWord" && (
              <Form.Item label="Options">
                <Checkbox
                  checked={useUppercase}
                  onChange={(e) => setUseUppercase(e.target.checked)}
                >
                  Capitalize initials
                </Checkbox>
                <Checkbox
                  checked={addNumber}
                  onChange={(e) => {
                    setAddNumber(e.target.checked);
                    generateUsername();
                  }}
                >
                  Add number
                </Checkbox>
              </Form.Item>
            )}
            {usernameType === "catchAll" && (
              <Form.Item label="Domain">
                <Input
                  value={domainInput}
                  onChange={(e) => setDomainInput(e.target.value)}
                  size="large"
                  placeholder="example.com"
                  className="service-input"
                />
              </Form.Item>
            )}

            {usernameType === "plusEmail" && (
              <Form.Item label="Email Address">
                <Input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  size="large"
                  className="service-input"
                />
              </Form.Item>
            )}

            {usernameType === "maskedEmail" && (
              <>
                <Form.Item label="Service" className="service-select">
                  <Select
                    value={maskedEmailService || undefined}
                    onChange={(value) => setMaskedEmailService(value)}
                    defaultValue="default"
                    options={[
                      {
                        value: "default",
                        label: "Choose a service",
                      },
                      {
                        value: "addy.io",
                        label: "addy.io",
                      },
                      {
                        value: "duckDuckGo",
                        label: "DuckDuckGo",
                      },
                      {
                        value: "fastmail",
                        label: "Fastmail",
                      },
                      {
                        value: "firefoxRelay",
                        label: "Firefox Relay",
                      },
                      {
                        value: "forwardEmail",
                        label: "Forward Email",
                      },
                      {
                        value: "simpleLogin",
                        label: "SimpleLogin",
                      },
                    ]}
                  />
                </Form.Item>

                {maskedEmailService === "addy.io" && (
                  <div className="masket-input">
                    <Form.Item label="API Access Token">
                      <Input
                        type="password"
                        value={apiAccessToken}
                        onChange={(e) => setApiAccessToken(e.target.value)}
                        size="large"
                        className="service-input"
                        placeholder="Enter your API token"
                      />
                    </Form.Item>
                    <Form.Item label="Alias Domain Name">
                      <Input
                        value={aliasInput}
                        onChange={(e) => setAliasInput(e.target.value)}
                        size="large"
                        className="service-input"
                        placeholder="Enter the Alias ​​domain name"
                      />
                    </Form.Item>
                  </div>
                )}

                {maskedEmailService === "duckDuckGo" && (
                  <Form.Item label="API key">
                    <Input
                      type="password"
                      value={apiAccessToken}
                      onChange={(e) => setApiAccessToken(e.target.value)}
                      size="large"
                      className="service-input"
                      placeholder="Enter your API key"
                    />
                  </Form.Item>
                )}

                {maskedEmailService === "fastmail" && (
                  <Form.Item label="API Access Token">
                    <Input
                      type="password"
                      value={apiAccessToken}
                      onChange={(e) => setApiAccessToken(e.target.value)}
                      size="large"
                      className="service-input"
                      placeholder="Enter your API token"
                    />
                  </Form.Item>
                )}

                {maskedEmailService === "firefoxRelay" && (
                  <Form.Item label="API Access Token">
                    <Input
                      type="password"
                      value={apiAccessToken}
                      onChange={(e) => setApiAccessToken(e.target.value)}
                      size="large"
                      className="service-input"
                      placeholder="Enter your API token"
                    />
                  </Form.Item>
                )}
                {maskedEmailService === "forwardEmail" && (
                  <div className="masket-input">
                    <Form.Item label="API Access Token">
                      <Input
                        type="password"
                        value={apiAccessToken}
                        onChange={(e) => setApiAccessToken(e.target.value)}
                        size="large"
                        className="service-input"
                        placeholder="Enter your API token"
                      />
                    </Form.Item>
                    <Form.Item label="Alias ​​Domain Name">
                      <Input
                        value={aliasInput}
                        onChange={(e) => setAliasInput(e.target.value)}
                        size="large"
                        className="service-input"
                        placeholder="Enter the Alias ​​domain name"
                      />
                    </Form.Item>
                  </div>
                )}
                {maskedEmailService === "simpleLogin" && (
                  <Form.Item label="API key">
                    <Input
                      type="password"
                      value={apiAccessToken}
                      onChange={(e) => setApiAccessToken(e.target.value)}
                      size="large"
                      className="service-input"
                      placeholder="Enter your API key"
                    />
                  </Form.Item>
                )}
              </>
            )}
          </Form>

          <Button
            type="primary"
            onClick={handleGenerateUsername}
            style={{ marginBottom: "16px", marginRight: "8px" }}
          >
            Create New Username
          </Button>
          <Button onClick={() => navigator.clipboard.writeText(username)}>
            Copy Username
          </Button>
        </div>
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
}

export default Builder;
