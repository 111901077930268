import React, { useEffect } from "react";
import { Modal, Form, Input, Button, message } from "antd";
import axios from "axios";

const EditFolderModal = ({
  isFolderEditModalVisible,
  setIsFolderEditModalVisible,
  editingFolder,
}) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [folderEditForm] = Form.useForm();

  useEffect(() => {
    if (editingFolder) {
      folderEditForm.setFieldsValue({
        name: editingFolder.name,
      });
    }
  }, [editingFolder, folderEditForm]);

  const handleUpdateFolder = async (values) => {
    const token = localStorage.getItem("token");
    try {
      await axios.put(
        `${API_URL}/api/folders/update-folder`,
        { ...values, id: editingFolder.id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      folderEditForm.resetFields();
      setIsFolderEditModalVisible(false);
      message.success("Folder updated successfully");
    } catch (error) {
      console.error("Error updating folder:", error);
      message.error("Failed to update folder");
    }
  };

  return (
    <Modal
      title="Edit Folder"
      open={isFolderEditModalVisible}
      onCancel={() => setIsFolderEditModalVisible(false)}
      footer={null}
    >
      <Form form={folderEditForm} onFinish={handleUpdateFolder}>
        <Form.Item
          name="name"
          label="Folder Name"
          rules={[
            {
              required: true,
              message: "Please input the name of the folder!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditFolderModal;
